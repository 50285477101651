.deposit-top-content.my_row { background: #323131; }
.DepositButtonGroup { border: none; height: 33px; width: 96px; background: transparent; color: white; }
.activeButtonCrypto { background: #464646; transition: 0.5s; }
.coin-img-container.depCoin-img img { max-width: 18px; width: 18px; margin-right: 2px; }
.wallet-crypto-coins { justify-content: space-around; margin: 20px 0px; }
.coinsSelect { border: none; height: 50px; width: 205px; background: #202020; color: white; font-weight: bold; padding-left: 48px; }
.dropDown-Coin-Img img { max-width: 30px; }
.dropDown-Coin-Img.absolute { top: 11px; left: 11px; }
.crypto-wallet-network { height: 46px; display: flex !important; border: 1px solid #1e2024; background: #323336; }
.activeNetworkButton { background-color: #42444a; transition: 0.5s; }
.wallet-crypto-network-container { overflow-x: hidden;  /* margin: 20px 0; */ }
.slick-next { right: 0px; }
.slick-prev { left: 0px; z-index: 1; }
.wallet-qr-qr-code-container img { max-width: 135px; }
.qr-deposit-adress { font-size: 13px; }
.qr-hash-container { font-size: 16px; }
.wallet-qr-typo-container { width: 250px; margin-top: 10px; text-align: center; }
.wallet-qr-content-container.my_row { gap: 20px; }
.wallet-qr-qr-code-container { background: #393a3a; padding: 11px; }
button.qr-copy-button { background: #393a3a; border: none; padding: 14px 24px; }
.qr-deposit-adress { margin-bottom: 11px; }
.qr-hash-container { margin-bottom: 20px; }
.wallet-coint-qr-container { background: #17181b; padding: 20px 0; }
.wallet-inner-tabs-main { overflow-y: scroll; height: 580px; }
.deposit-img-container img { max-width: 30px; }
.depositOption-Container { gap: 18px; }
.deposit-options.width { gap: 40px; }
.wallet-options-conainer-container { margin-top: 15px; background: #17181b; padding: 20px; }
.width.my_row.justify { margin-bottom: 10px; }
.wallet-notice-conainer-container { margin: 20px 0; }
.wallet-crypto-dropdown-bottom { margin: 10px 0; background: #17181b; padding: 13px; }
.wallet-coint-qr-container { margin-top: 15px; margin-bottom: 40px; }