@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
}
:root {
  --primary: #5f2fce;
  --light: #f3f6f9;
  --dark: #191c24;
  --black: #000;
}
body {
  font-family: "Montserrat", sans-serif;
  background-color: #24262b;
}
button:active {
  scale: 0.96 !important;
  transition: 0.1s !important;
}
::-moz-selection {
  color: #000;
  background: #fff;
}
::selection {
  color: #000;
  background: #fff;
}
::-webkit-scrollbar {
  width: 4px !important;
  height: 4px !important;
}
::-webkit-scrollbar-thumb {
  background: #000 !important;
  border-radius: 10px !important;
}
::-webkit-scrollbar-thumb:hover {
  background: #2c2c2c;
}
.font-size-11px {
  font-size: 11px !important;
}
.font-size-12px {
  font-size: 12px !important;
}
.font-size-13px {
  font-size: 13px !important;
}
.font-size-14px {
  font-size: 14px !important;
}
.font-size-15px {
  font-size: 15px !important;
}
.font-size-16px {
  font-size: 16px !important;
}
.font-size-17px {
  font-size: 17px !important;
}
.font-size-18px {
  font-size: 18px !important;
}
.font-size-19px {
  font-size: 19px !important;
}
.font-size-20px {
  font-size: 20px !important;
}
.font-weight-normal {
  font-weight: normal;
}
a:hover {
  color: #2d93b6;
}
.logo {
  margin: 14px 0 13px 10px;
}
.logo img {
  height: 39px;
}
.sidebar-show-hide {
  background: none;
  padding: 0;
  margin: 0;
  border: none;
  line-height: 10px;
  border: none;
  outline: none;
}
span {
  word-wrap: break-word;
}
input:focus-visible {
  outline: 1px solid #1732c1;
}
select:focus-visible {
  outline: 1px solid #1732c1;
}
.pool-operations {
  background-color: #0c0c0c;
  color: #fff;
  border: 2px solid #0d2c23; /* border-color: #0d2c23; */
  font-weight: 600;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #d6d6d6;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
.pool-operations:hover {
  background-color: #0d7254;
  color: #fff;
}
.active-button {
  background-color: #1ba27a;
}
.page-width {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 20px;
}
.my_row {
  display: flex;
  flex-wrap: wrap;
}
.space-between {
  justify-content: space-between;
  align-items: center;
}
.align_center {
  align-items: center;
}
.end {
  justify-content: flex-end;
}
.justified {
  justify-content: "center";
  align-items: "center";
}
.my_row .my_row_item_4 {
  width: calc(100% / 4);
}
.my_row .my_row_item_5 {
  width: calc(100% / 5);
}
.my_row_item_6 {
  width: calc(100% / 6);
}
.my_row .my_row_item_2 {
  width: calc(100% / 2);
}
.justify {
  justify-content: center;
  align-items: center;
}
img {
  width: 100%;
  max-width: 100%;
}
button {
  cursor: pointer;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.bold {
  font-weight: bold;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
.input___color {
  color: #a0a0a0;
  background: #4e5158 !important;
}
.text_color {
  color: #717c87;
}
.white {
  color: white;
}
.justified {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.max-20 {
  max-width: 20px;
}
.max-30 {
  max-width: 30px;
}
.width {
  width: 100%;
}
.width-15 {
  width: 15%;
}
.width-20 {
  width: 20%;
}
.width-30 {
  width: 30%;
}
.width-35 {
  width: 35%;
}
.width-40 {
  width: 40%;
}
.width-50 {
  width: 50%;
}
.width-60 {
  width: 60%;
}
.width-70 {
  width: 70%;
}
.input-label-container {
  color: #d5d5d5;
}
.BankRollButton {
  margin-right: 459px;
  margin-bottom: 34px;
}
.pointer {
  cursor: pointer;
}
.gc {
  color: #43b309;
}
.BankRollMainClass {
  margin-right: 20px;
  margin-bottom: 55px;
}
.historyTab {
  margin-bottom: 55px;
}
.for_now {
  margin-top: 20px;
}
.width_for_force {
  width: 425px !important;
}
.my-global {
  margin: 20px 0;
}
.pagination-btn {
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
}
.pre_btn {
  background: conic-gradient(from 0deg at 5% 50%, #7141ee 0deg, #5b2ac6 360deg);
  border: none;
  padding: 0.5rem 0.95rem;
  color: #ffffff;
  transition: 0.5s;
  border-radius: 25rem !important;
  margin: 0.5rem !important;
}
.next_btn {
  background: conic-gradient(from 0deg at 5% 50%, #7141ee 0deg, #5b2ac6 360deg);
  border: none;
  padding: 0.5rem 0.95rem;
  color: #ffffff;
  transition: 0.5s;
  border-radius: 25rem !important;
  margin: 0.5rem !important;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
  border: 2px solid #000 !important;
  border-radius: 50%;
  padding: 0 !important;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  border: 2px solid #5c8ec3;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #5c8ec3 transparent transparent transparent;
  margin: -2px;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
.font {
  font-size: 12px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.buttons-err {
  background: linear-gradient(to right, rgb(192 58 58) 50%, rgb(168 33 17) 50%);
  color: #fff;
  font-weight: bold;
  border: none;
}
.buttons-disabled {
  background: linear-gradient(to right, rgb(78 78 78) 50%, rgb(60 60 60) 50%);
  color: #fff;
  font-weight: bold;
  border: none;
}
.security-phrase {
  margin: 10px 0;
}
/* .disabled-inputs { background: #3a3a3a !important; border: none !important; color: white !important; font-weight: bold !important; } */
.notifications-count {
  background: #de0303;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  font-size: 10px;
  color: #fff;
  letter-spacing: 0px;
  top: -6px;
  right: -4px;
  text-align: center;
  line-height: 17px;
}
.active-button {
  background-color: #1ba27a; /* color: #fff; */
}
.primary-btn {
  border-radius: 5px;
  padding: 11px;
  color: #fff;
  font-size: 14px;
  letter-spacing: 1px;
  outline: none;
  border: none;
  box-shadow: none;
  background: linear-gradient(0deg, #18599c 0%, #5d8fc4 100%);
}
.primary-btn:hover {
  background: linear-gradient(0deg, #113f6d 0%, #497db1 100%);
}
.buttons-primary {
  border-radius: 5px;
  padding: 11px;
  color: #fff;
  font-size: 14px;
  letter-spacing: 1px;
  outline: none;
  border: none;
  box-shadow: none;
  background: linear-gradient(0deg, #18599c 0%, #5d8fc4 100%);
}
.buttons-primary:hover {
  background: linear-gradient(0deg, #113f6d 0%, #497db1 100%);
}
.primary-btn-disable { background: linear-gradient(0deg, #4e5154 0%, #969a9f 100%); }
.primary-btn-disable:hover { background: linear-gradient(0deg, #2f3032 0%, #73777b 100%); }
.primary-btn-success { background: linear-gradient(0deg, #529c18 0%, #a9c45d 100%); }
.primary-btn-success:hover { background: linear-gradient(0deg, #448512 0%, #94ad4e 100%); }
.primary-btn-danger { background: linear-gradient(0deg, #9c1818 0%, #c45d5d 100%); }
.primary-btn-danger:hover { background: linear-gradient(0deg, #851212 0%, #ad4e4e 100%); }
.security-phrase {
  margin: 20px 0;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.page-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.back-to-top {
  position: fixed;
  display: none;
  right: 10px;
  bottom: 10px;
  z-index: 99;
}
/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-out, visibility 0s linear 0.5s;
  z-index: 99999;
}
#spinner.show {
  transition: opacity 0.5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}
/*** Button ***/
.btn {
  transition: 0.5s;
}
.btn.btn-primary {
  color: #ffffff;
}
.btn-square {
  width: 38px;
  height: 38px;
}
.btn-sm-square {
  width: 32px;
  height: 32px;
}
.btn-lg-square {
  width: 20px;
  height: 38px;
}
.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  border-radius: 50px;
}
/*** Layout ***/
.sidebar {
  position: fixed;
  top: 66px;
  left: 0;
  bottom: 0;
  width: 250px;
  overflow-y: auto;
  background: #1c1e22;
  transition: 0.5s;
  z-index: 999;
}
.content {
  margin-left: 250px;
  transition: 0.5s;
}
/*** Navbar ***/
.sidebar .navbar .navbar-nav .nav-link {
  padding: 6px;
  color: #828283;
  font-weight: 500;
  border-radius: 10px;
  outline: none;
  margin-bottom: 3px;
  transition: all 0.3s ease;
  font-size: 15px;
  position: relative;
}
.sidebar .navbar .navbar-nav .nav-link .sidebar-icon-white {
  display: none;
}
.sidebar .navbar .navbar-nav .nav-link.active .sidebar-icon-grey,
.sidebar .navbar .navbar-nav .nav-link:hover .sidebar-icon-grey {
  display: none;
}
.sidebar .navbar .navbar-nav .nav-link.active .sidebar-icon-white,
.sidebar .navbar .navbar-nav .nav-link:hover .sidebar-icon-white {
  display: inline-block;
}
.sidebar .navbar .navbar-nav .nav-link:after {
  content: "";
  background: linear-gradient(270deg, #5c8ec3 10%, #2f3238 70%);
  transition: all 0.3s ease;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  border-radius: 4px;
  width: 0px;
}
.sidebar .navbar .navbar-nav .nav-link:hover:after,
.sidebar .navbar .navbar-nav .nav-link.active:after {
  transition: all 0.3s ease;
  right: 0;
  width: 100%;
}
.sidebar .navbar .navbar-nav .nav-link:hover,
.sidebar .navbar .navbar-nav .nav-link.active {
  color: #fff;
  transition: all 0.3s ease;
}
.sidebar .navbar .navbar-nav .nav-link i {
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 40px;
}
.sidebar .navbar .navbar-nav .nav-link:hover i,
.sidebar .navbar .navbar-nav .nav-link.active i {
  background: var(--light);
}
.sidebar .navbar .navbar-nav .nav-link img {
  position: relative;
  top: -1px;
  margin-right: 10px;
  max-width: 20px;
}
.sidebar .navbar .dropdown-toggle::after {
  position: absolute;
  top: 15px;
  right: 15px;
  border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  transition: 0.5s;
}
.sidebar .navbar .dropdown-toggle[aria-expanded="true"]::after {
  transform: rotate(-180deg);
}
.sidebar .navbar .dropdown-item {
  padding-left: 25px;
  border-radius: 0 30px 30px 0;
}
.content .navbar .navbar-nav .nav-link {
  margin-left: 25px;
  padding: 12px 0;
  color: var(--dark);
  outline: none;
}
.content .navbar .navbar-nav .nav-link:hover,
.content .navbar .navbar-nav .nav-link.active {
  color: var(--primary);
}
.content .navbar .sidebar-toggler,
.content .navbar .navbar-nav .nav-link i {
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 40px;
}
.content .navbar .dropdown-toggle::after {
  margin-left: 6px;
  vertical-align: middle;
  border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  transition: 0.5s;
}
.content .navbar .dropdown-toggle[aria-expanded="true"]::after {
  transform: rotate(-180deg);
}
/*** Date Picker ***/
.bootstrap-datetimepicker-widget.bottom {
  top: auto !important;
}
.bootstrap-datetimepicker-widget .table * {
  border-bottom-width: 0px;
}
.bootstrap-datetimepicker-widget .table th {
  font-weight: 500;
}
.bootstrap-datetimepicker-widget.dropdown-menu {
  padding: 10px;
  border-radius: 2px;
}
.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
  background: var(--primary);
}
.bootstrap-datetimepicker-widget table td.today::before {
  border-bottom-color: var(--primary);
}
.progress .progress-bar {
  width: 0px;
  transition: 2s;
}
/*** Testimonial ***/
.testimonial-carousel .owl-dots {
  margin-top: 24px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.testimonial-carousel .owl-dot {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  width: 15px;
  height: 15px;
  border: 5px solid var(--primary);
  border-radius: 15px;
  transition: 0.5s;
}
.testimonial-carousel .owl-dot.active {
  background: var(--dark);
  border-color: var(--primary);
}
.assets-operator {
  color: #d7d8da;
  font-size: 14px;
  margin: 0 3px;
  cursor: pointer;
}
.table-style {
  border: none;
}
.table-style tr {
  border: none;
}
.table-style th {
  border: none;
  color: #c9c9c9;
  font-weight: 600;
  font-size: 13px;
}
.table-style td {
  border: none;
  font-size: 13px;
  color: #9a9a9a;
}
.table-style td .view,
.table-style td .edit,
.table-style td .delete {
  color: #d7d8da;
  font-size: 13px;
  margin: 0 3px;
}
.table-style td .view.primary-btn,
.table-style td .edit.primary-btn ,
.table-style td .delete.primary-btn{
  padding: 4px 6px;
  border-radius: 3px;
}
.table-style td .yes {
  background: #48b122;
  border: none;
  padding: 1px 15px;
  font-size: 14px;
}
.table-style td .no {
  background: #57585b;
  border: none;
  padding: 1px 15px;
  font-size: 14px;
}
.table-style th input[type="checkbox"],
.table-style td input[type="checkbox"] {
  border: 1px solid #687077;
  background: none;
}
.color-white {
  color: #fff;
}
.top-nav {
  background: #24262b;
  box-shadow: 0 0px 25px rgb(0 0 0 / 25%);
}
.user-pic {
  width: 40px;
  height: 40px;
}
.user-profile-pic {
  width: 43px;
  height: 43px;
  border: solid 1px #484e53;
}
.loader-home {
  width: 3rem;
  height: 3rem;
}
.table-bg {
  background: #1e2024;
  border-radius: 20px !important;
}
.active-bets {
  color: #818386;
}
.view-all {
  color: #818386;
  font-size: 14px;
}
/* .top-right-user { background: #1c1e22; border-radius: 40px; margin: 5px; } */
.top-right-user p {
  color: #fff;
  font-size: 13px;
  top: 3px;
  position: relative;
  margin-left: 5px;
}
.top-right-user p b {
  display: block;
  line-height: 12px;
}
.top-right-user p span {
  font-size: 11px;
  color: #656d74;
}
.top-right-user .primary-btn {
  margin: 0 0 0 8px;
  position: relative;
}
.dashboard-user-box {
  margin-bottom: 10px;
  padding-bottom: 10px !important;
  border-bottom: solid 1px #2a2a2a;
}
.page-title h1 {
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 15px;
}
.page-title h3 {
  font-size: 18px;
  color: #99a4af;
  font-weight: 400;
  margin-bottom: 15px;
}
.page-title h4 {
  font-size: 14px;
  color: #818386;
  font-weight: 400;
  margin-bottom: 15px;
}
.page-title select {
  max-width: 200px;
  appearance: auto;
  margin-bottom: 20px;
  color: #687077;
  background: #2b2d32 !important;
  border: none;
}
.dashboard-summary {
  border-radius: 20px;
  background: #1a1b20;
  text-align: center;
  padding: 30px 0;
}
.dashboard-summary h1 {
  color: #dddddd;
  margin-bottom: 15px;
  font-size: 30px;
  font-weight: 700;
}
.dashboard-summary p {
  color: #818386;
  font-size: 13px;
  font-weight: 600;
}
.dashboard-summary-arrow {
  clear: both;
  overflow: hidden;
  padding: 0 30px;
}
.dashboard-summary-arrow .text {
  float: left;
  font-size: 16px;
}
.dashboard-summary-arrow .arrow {
  float: right;
  font-size: 16px;
}
.dashboard-summary-arrow .arrow.green {
  color: #4db726;
}
.dashboard-summary-arrow .arrow.red {
  color: #eb631e;
}
.dashboard-summary-arrow .arrow.bullet span {
  background: #4eb726;
  width: 16px;
  height: 16px;
  display: inline-block;
  border-radius: 50%;
}
.page-filters {
  background: #1e2024;
  border-radius: 20px !important;
  padding: 20px 30px 15px 30px;
  margin-bottom: 20px;
}
.page-filters label {
  font-size: 14px;
  color: #687077;
}
.page-filters select {
  background: #4e5158;
  border: none;
  color: #a0a0a0;
  font-size: 14px;
  appearance: auto;
  outline: none;
}
.page-filters select.form-control {
  background: #4e5158;
  border: none;
  color: #a0a0a0;
  font-size: 14px;
  padding: 8px 7px;
  text-align: left;
}
.page-filters input[type="text"] {
  background: #37383c;
  border: none;
  box-shadow: none;
}
.page-filters .apply-filters {
  background: conic-gradient(
    from 0deg at 50% 50%,
    #62d832 0deg,
    #4bb624 360deg
  );
  border: none;
}
.page-filters .reset-filters {
  background: conic-gradient(
    from 0deg at 50% 50%,
    #afafaf 0deg,
    #4e4e4e 360deg
  );
  border: none;
}
.page-filters .star {
  color: red;
}
.table-bet-rounds-detail tr {
  border-bottom: 1px solid #363636;
}
.table-bet-rounds-detail tr:last-child {
  border-bottom: none;
}
.table-bet-rounds-detail td {
  text-align: left;
}
.signin-box {
  background: #000;
}
.signin-box .form-control {
  background: #37383c;
  border: none;
  color: #fff;
}
.react-datepicker__input-container input[type="text"] {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #757575;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: none;
  background: #37383c !important;
  outline: none;
}
.signin-box .form-control {
  background: #37383c;
  border: none;
  color: #757575;
}
.MuiBox-root {
  outline: none !important;
  padding: 18px !important;
  border: none !important;
  box-shadow: none !important;
}
.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.8) !important;
}
.security-phrase-popup {
  padding: 20px !important;
  border: none !important;
}

.pagination-buttons-container {
  justify-content: center;
  margin: 20px 0 5px 0;
}
.pagination-buttons-container .next-pre-btn {
  width: 250px;
  background: #16171a;
  padding: 5px;
  border-radius: 5px;
}
.pagination-buttons-container .next-pre-btn .pre-btn svg,
.pagination-buttons-container .next-pre-btn .next-btn svg {
  font-size: 15px;
}
.pagination-buttons-container .next-pre-btn span.white {
}
.pagination-buttons-container .next-pre-btn input[type="number"] {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #9a9898;
  background-color: #37383c !important;
  background-clip: padding-box;
  border: none;
  appearance: none;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: none !important;
  background: #000 !important;
  margin-right: 9px;
  border-radius: 5px;
  display: initial;
  min-width: 73px !important;
  outline: none;
}
.hash-key-scroll {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  direction: rtl;
  width: 120px;
  margin: auto;
}

#active-activeAll { width: 0; height: 0; opacity: 0; }
.active-activeAll { display: block; height: 16px; width: 60px; position: relative; vertical-align: middle; cursor: pointer; margin: auto; }
span.active-activeAll-slide { position: absolute; width: 100%; height: 100%; background: #7a7a7a; border-radius: 30px; left: 0; }
span.active-activeAll-slide:before { position: absolute; content: ""; height: 20px; width: 20px; background: #4c4c4c; border-radius: 50%; top: -2px; left: 0px; transition: 0.5s; box-shadow: rgba(0, 0, 0, 0.21) 0px 0px 7px 1px; }
#active-activeAll:checked + span.active-activeAll-slide:before { transform: translatex(40px); background: #fff; }

#active-activeAll-1 { width: 0; height: 0; opacity: 0; }
.active-activeAll-1 { display: block; height: 16px; width: 60px; position: relative; vertical-align: middle; cursor: pointer; margin: auto; }
span.active-activeAll-1-slide { position: absolute; width: 100%; height: 100%; background: #7a7a7a; border-radius: 30px; left: 0; }
span.active-activeAll-1-slide:before { position: absolute; content: ""; height: 20px; width: 20px; background: #4c4c4c; border-radius: 50%; top: -2px; left: 0px; transition: 0.5s; box-shadow: rgba(0, 0, 0, 0.21) 0px 0px 7px 1px; }
#active-activeAll-1:checked + span.active-activeAll-1-slide:before { transform: translatex(40px); background: #fff; }

/*
////////////////////////////////////////////////////////////////
RESPOSNIVENESS
////////////////////////////////////////////////////////////////
*/
@media (min-width: 992px) {
  .sidebar {
    margin-left: 0;
  }
  .leftbar-content-toggle .sidebar {
    margin-left: -250px;
  }
  .content {
    width: calc(100% - 250px);
  }
  .leftbar-content-toggle .content {
    width: 100%;
    margin-left: 0;
  }
}
@media (max-width: 991.98px) {
  .sidebar {
    margin-left: -250px;
  }
  .leftbar-content-toggle .sidebar {
    margin-left: 0;
  }
  .content {
    width: 100%;
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  .table-style {
    min-width: 900px;
  }
}
@media (min-width: 701px) {
  .header-profile-box-icon {
    display: none;
  }
}
@media (max-width: 700px) {
  .header-profile-box-icon {
    color: #fff;
    padding: 21px 4px;
  }
  .header-profile-box-icon:focus {
    color: #fff;
  }
  .header-profile-box {
    transition: all 0.3s ease;
    position: absolute;
    right: 16px;
    top: 50px;
    display: none;
  }
  .header-profile-box .top-right-user {
    display: block !important;
    padding: 10px 10px 0px 10px;
    text-align: center;
    border: solid 1px #32343a;
    overflow: hidden;
    border-radius: 10px;
    background: #24262b;
  }
  .header-profile-box-toggle {
    display: block;
  }
  .top-right-user .primary-btn {
    display: block;
    margin: 0 0 10px 0;
    width: 100%;
  }
  .active-bets {
    font-size: 12px;
  }
  .view-all {
    font-size: 12px;
  }
  .table-style th {
    font-size: 12px;
    padding: 10px 5px;
  }
  .table-style td {
    font-size: 12px;
    padding: 10px 5px;
  }
  .table-style td .view,
  .table-style td .edit,
  .table-style td .delete {
    font-size: 12px;
  }
}
@media (max-width: 500px) {
  .security-phrase-popup {
    width: 95% !important;
  }
}
 
